jQuery(document).ready(function ($) {

    $('.slider-photo').on('init', function (event, slick) {
        $(".slider-photo [data-slick-index='" + slick.currentSlide + "'] video").each(function () {
            this.play()
        });
    });

    $('.slider-photo').slick({
        dots: true,
        speed: 300,
        infinite: true,
        autoplay: true,
        fade: true,
        autoplaySpeed: 5000,
        cssEase: 'ease-out',
        arrows: false,
        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>',
    });

    $('.slider-photo').on('afterChange', function (event, slick, currentSlide) {
        $(".slider-photo [data-slick-index='" + currentSlide + "'] video").each(function () {
            this.play()
        });
    });

    $('.slider-photo').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        $(".slider-photo [data-slick-index='" + currentSlide + "'] video").each(function () {
            this.pause()
        });
    });

    $('.slider-portfolio').slick({
        infinite: true,
        slidesToShow: 2,
        autoplay: true,
        autoplaySpeed: 10000,
        slidesToScroll: 1,
        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-long-arrow-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fa fa-long-arrow-right"></i></button>',
        appendArrows: '.slider-portfolio__arrows',
        responsive: [
            {
                breakpoint: 1281,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });

    $('.slider-reviews').slick({
        dots: true,
        infinite: true,
        slidesToShow: 1,
        autoplay: true,
        autoplaySpeed: 10000,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    adaptiveHeight: true,
                }
            }
        ],
    });

    $('.slider-logo').slick({
        dots: false,
        speed: 300,
        infinite: true,
        autoplay: true,
        fade: false,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplaySpeed: 5000,
        cssEase: 'ease-out',
        arrows: true,
        prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-angle-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fa fa-angle-right"></i></button>',
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                }
            },
            {
                breakpoint: 800,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ],
    });


});
